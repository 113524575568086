<template>
  <div class="search-popup">
      <van-search
        v-model="searchVal"
        show-action
        :placeholder="placeholderVal"
        @search="onSearch"
        @cancel="onCancel"
        @input="onInput"
      />
       <!-- @input="onInput" 表示输入框内容发生变化的时候触发onInput函数 -->
      <HistoryHot 
        v-if="blockShow == 1" 
        :historyListData = "historyListData" 
        :hotKeywordList = "hotKeywordList"
        @tagClick = "tagClick"
      />
      <SearchTipsList 
        v-else-if="blockShow == 2" 
        :searchTipsListData ="searchTipsListData"
        @cellClick = "tagClick"
      />
      <SearchProducts  v-else
        :filterLearning = "filterLearning"
        :goodsList = "goodsList"
        @learningChange = "learningChange"
        @priceChange= "priceChange"
       />
      <!-- <comp1 v-if="blockShow == 1"></comp1>
      <comp2 v-else-if="blockShow == 2"></comp2>
      <comp3 v-else></comp3> -->
  </div>
</template>

<script>
import HistoryHot from '@/components/HistoryHot'
import SearchTipsList from '@/components/SearchTipsList'
import SearchProducts from '@/components/SearchProducts'
import { GetSearchPopuData, GetSearchTipsListData, GetGoodsListData } from '@/request/api'

export default {
  data () {
    return {
      // 搜索的文字,也就是用户输入的文本
      searchVal: "",
      // 占位符文本
      placeholderVal: "",
      // 值可以为1或2或3,
      // 为1, 表示展示历史记录和热门搜索
      // 为2, 表示展示搜索提升的列表
      // 为3, 表示展示搜索的产品的内容
      blockShow: 1,
      // 历史记录的列表数据
      historyListData: [],
      // 热门搜索的列表数据
      hotKeywordList: [],
      // 搜索实时提示列表数据
      searchTipsListData: [],
      // 搜索产品内容的分类数据
      filterLearning: [],
      // 搜索产品内容的列表数据
      goodsList: [],
      // 价格排序  (有高到低 或者 由低到高)
      order: "desc",
      // // 分类id
      learningId: 0,
      // // 默认搜索方式是 id还是price
      sort: "id"
 
    }
  },
  created() {
    GetSearchPopuData().then(res => {
      if(res.data.errno == 0) {
        // console.log(res.data);
        // console.log(res.data.data.defaultKeyword.keyword);
        this.placeholderVal = res.data.data.defaultKeyword.keyword
        this.historyListData = res.data.data.historyKeywordList
        this.hotKeywordList = res.data.data.hotKeywordList
      }
    })
  },
  methods: {
    tagClick(val){
      // 改变上面搜索框的值,后慢慢给价格排序的时候调用到priceChange()的时候传入的就是这次点击的这个值
      this.searchVal = val
      this.onSearch(val)
    },
    priceChange(value) { // value为desc或者asc
      this.order = value
      this.sort = "price"
      this.onSearch(this.searchVal)

    },
    learningChange(value) {
      // 改id
      this.learningId = value
      // 发送搜索商品的请求
      this.onSearch(this.searchVal)
    },

    onSearch(val) {  // onSearch用户回车搜索的时候执行,val就是用户输入的值
      // console.log("onSearch", val);
      this.blockShow = 3;
      GetGoodsListData({
        keyword: val,
        page: 1,
        size: 10,
        order: this.order,
        learningId: this.learningId,
        sort: this.sort

     }).then(res => {
        if(res.data.errno == 0) {
          // console.log(res.data);
          // this.filterLearning = res.data.data.filterLearning
          // this.goodsList = res.data.data.goodsList
          let {filterLearning, goodsList} = res.data.data;
          
          this.goodsList = goodsList
          let newArr = JSON.parse(JSON.stringify(filterLearning).replace(/name/g, 'text')
          .replace(/id/g, 'value'))
          // JSON.parse(JSON.stringify(data).replace(/name1/g, 'new_name1')
          // .replace(/name2/g, 'new_name2').replace(/name3/g, 'new_name3')...)
          
          // console.log(newArr);
          this.filterLearning = newArr
        }
      })
    },
    onCancel() {
      // console.log("onCancel");
      // this.$router.push("/home")
      this.$router.go(-1)
      // this.$store.commit("changeIsShowPopupShow", false)

    },
    onInput(val) {
      this.blockShow = 2
      // console.log(val);
      // 发送请求,获取实时搜索的数据的列表
      GetSearchTipsListData({keyword:val}).then(res=> {  
        // console.log(res.data);
        if(res.data.errno == 0) {
          // console.log(res.data);
          this.searchTipsListData = res.data.data
        }
      })
    }
  },
  components: {
    HistoryHot,
    SearchTipsList,
    SearchProducts
  }
}
</script>
 
<style lang = "less" scoped>
  .search-popup {
    width: 100%;
    height: 100%;
    position: fixed;

    z-index: 99999;
    top: 0;
    left: 0;
     background-color: rgba(0, 0, 0, .5); 
    // background-color: #e7e9ed;
  }
</style>
<style>
  .van-search {
    height: 6rem;
    padding: 0.8rem 0.6rem;
  }
  .van-search__content {
    height: 100%;
    border-radius: 10rem;
  }
  .van-search__content .van-cell .van-icon {
    font-size: 2.4rem !important;
    line-height: 4.6rem;
    padding: 0 1.2rem;
  }
  .van-field__body {
    height: 100%;
    line-height: 4rem;
    font-size: 2rem;
  }
  .van-search__action {
    padding: 0 1.6rem 0 1rem;
    font-size: 2rem;
  }
  .his-hot .hd h4 {
    font-size: 2rem !important;
    padding: .6rem;
  }
  .his-hot .hd .van-icon {
    font-size: 2.4rem !important;
    padding: .6rem 2rem .6rem .6rem;
  }
  .his-hot .van-tag {
    font-size: 1.8rem !important;
    padding: .6rem !important;
    height: 1.8rem;
    border-radius: .3rem;
    margin: .6rem;
  }

  .van-list {
    height: 5rem;
  }
  .van-list .van-cell {
    height: 100%;
    padding: 0 1.6rem;
  }
  .van-list .van-cell .van-cell__title {
    line-height: 5rem;
    font-size: 2rem;
  }
</style>